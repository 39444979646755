import clsx from "clsx"
import { ApiService, ApiShowError, formatCurrent, sortByNumber } from "../../../../theme/helpers"
import { InputPrice, InputSelect, KTFormItem, KTTable } from "../../../../theme/partials"
import { Modal, Offcanvas } from "react-bootstrap"
import { popupLoading, popupMessage, popupMessageHTMl, popupQuestion } from "../../../modules/messages"
import { useStoreMode } from "../../../modules/auth/core/store"
import { useEffect, useRef, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { CountUpTimer, formatValue, getColorTrans } from "./functionOrder"
import { STATUSTABLE } from "../../tableService/tabs/constant"
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from "react"
import Swal from "sweetalert2"
import { arrNote } from "./addOrder"
import { useTranslation } from "react-i18next"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import localforage from "localforage"
import { checkConnectPrinter } from "../../mode/print"
export const KITCHENSTATUS = {
    stay: 'Stay',
    sent: 'Sent',
    notSent: 'Not Sent',
    open: 'Open',
    served: 'Served',
    finished: 'Finished',
    voided: 'Voided',
    hold: 'Hold',
}
export const caculatorPriceAmount = (item: any) => {
    // console.log('item caculatorPriceAmount',item)
    let vat = item.vat
    let discount = item.discount || 0
    // let coupondiscountpercent = item.coupondiscountpercent || 0
    let discountamount = (+item.discountamount||0) + (+item.coupondiscountamount||0)//(item.price * item.quantity)*(discount/100) + (item.price * item.quantity)*(coupondiscountpercent/100)
    let amount = (item.price * item.quantity) - discountamount
    let vatamount = item.vatamount
    if(vat) {
        vatamount = amount*((vat/100)/(1+(vat/100)))
    }
    let netamount = amount - vatamount
    if(item.vatamount && !vat) {
        vat = ((amount - netamount)*100/netamount).toFixed(0)
    }
    let netprice = item.netprice || item.price/ (1+(vat/100))
    let a = {
        price: item.price,
        netprice: netprice,
        quantity: item.quantity,
        vat: vat,
        discount: discount,
        discountamount: discountamount,
        netamount: netamount,
        vatamount: vatamount,
        amount: amount,
    }
    // console.log('a',a)

    return {
        ...item,
        ...a,
    }
}
export const caculPromo = (HospTransactionLines: any, dataHosp: any) => {
    let {totaldiscount} = dataHosp
    let data = {
        taxamount: 0,
        linediscount: 0,
        grossamount: 0,
        subtotal: 0,
        netamount: 0,
    } as any
    // console.log('caculPromo',HospTransactionLines)
    HospTransactionLines?.filter((i: any) => i.kitchenstatus != KITCHENSTATUS.voided)?.forEach((i: any) => {
        // if(i.kotline?.isvoided || i.kitchenstatus == KITCHENSTATUS.voided) {
        //     return
        // }
        let cusvatamount = 0
        let cusnetamount = 0
        let cusamount = 0
        let cusdiscountamount = 0
        if(i.customizations) {
            cusamount = i.customizations.reduce(function (acc:any, obj:any) { return acc + +obj.amount}, 0)||0
            cusnetamount = i.customizations.reduce(function (acc:any, obj:any) { return acc + +obj.netamount }, 0)||0
            cusvatamount = i.customizations.reduce(function (acc:any, obj:any) { return acc + +obj.vatamount }, 0)||0
            cusdiscountamount = i.customizations.reduce(function (acc:any, obj:any) { return acc + +obj.discountamount }, 0)||0
            
        }
        let vatamount = +i.vatamount + cusvatamount
        let totaldiscount = (+i.discountamount||0) + (+i.coupondiscountamount||0)  + +cusdiscountamount
        let grossamount = +i.amount + cusamount
        data.netamount += (+i.netamount||0) + +cusnetamount
        data.taxamount += +vatamount
        data.linediscount += totaldiscount
        data.grossamount += grossamount
    })

    data.subtotal = data.grossamount + data.linediscount + (+totaldiscount||0) 
    // console.log('data.caculPromo',data)
    return data
}
export const MemberInforAndNote = (props: any) => {
    const { t } = useTranslation()
    const {note, setDataModal,customerinfo,id, coupon, funcCalculateBasket} = props
    const [membercontacts,setMembercontacts] = useState<any>(null)
    const [dataInfo,setDataInfo] = useState<any>(null)
    useEffect(()=>{
        if(customerinfo && customerinfo?.cardNo) {
            setDataInfo(customerinfo)
           async function getMember () {
            let apiCurrent = `lscentral/membercontacts/findbycardno/${customerinfo.cardNo}`
            let {data} = await ApiService.get(apiCurrent)
            if(data && data.length !=0){
                setMembercontacts(data[0])
            }
           }
           getMember() 
                
        }
        else {
            setDataInfo(null)
        }
    },[customerinfo?.cardNo])
    const clearMember = async() => {
        let aws = await popupQuestion({title: t('questions-deep.delete-member')})
        if(aws) {
            let tempData = {
                name: 'Guest',
            }
            let dataUpdate = await ApiService.put(`smarthub/hosptransactions/updatecustomerinfo/${id}`,{customerinfo:tempData})
            if(dataUpdate.data) {
                let customerinfo = dataUpdate.data?.customerinfo
                let data = JSON.parse(JSON.stringify(props))
                data.customerinfo = customerinfo
                // console.log('data',data)
                funcCalculateBasket && funcCalculateBasket(data)
                // setDataModal(data)
                setMembercontacts(null)
                setDataInfo(null)
            }
            popupMessage({ icon: 'success', autoClose: true })
        }
        
    }
    const clearCoupon = async(index: any) => {
        let aws = await popupQuestion({title: t('questions-deep.delete-coupon')})
        if(aws) {
            // return
            let tempData = {
                coupon: coupon.filter((i: any,ind: number) => ind != index),
            }
            let dataUpdate = await ApiService.put(`smarthub/hosptransactions/updatecustomerinfo/${id}`,tempData)
            if(dataUpdate.data) {
                let coupon = dataUpdate.data?.coupon
                let data = JSON.parse(JSON.stringify(props))
                data.coupon = coupon
                // console.log('data',data)
                funcCalculateBasket && funcCalculateBasket(data)
                // setDataModal(data)
                // setMembercontacts(null)
                // setDataInfo(null)
            }
            popupMessage({ icon: 'success', autoClose: true })
        }
        
    }
    const clearEInvoice = async() => {
        let aws = await popupQuestion({title: t('questions-deep.delete-einvoice')})
        if(aws) {
            // return
            let tempData = {
                einvoice: {},
            }
            let dataUpdate = await ApiService.put(`smarthub/hosptransactions/updatecustomerinfo/${id}`,tempData)
            if(dataUpdate.data) {
                let einvoice = dataUpdate.data?.einvoice
                let data = JSON.parse(JSON.stringify(props))
                data.einvoice = einvoice
                setDataModal(data)
                // funcCalculateBasket && funcCalculateBasket(data)
            }
            popupMessage({ icon: 'success', autoClose: true })
        }
        
    }
    return <div className="px-3">
        <div className={clsx(`mb-1`)}>
            {
                coupon && coupon.length != 0 && coupon?.map((i: any,index: number) => (
                    <div className="d-flex flex-stack gap-2" key={index}>
                        <div className="w-100">
                            <i className="text-danger bi bi-gift me-1 fs-5"></i>Coupon {i.code} - {i.description}
                        </div>
                        {
                        funcCalculateBasket &&
                        <span onClick={e=>clearCoupon(index)}><i className="bi bi-trash3 text-reset fs-4 cursor-pointer"></i></span>
                        
                        }
                    
                    </div>
                ))
            }
        </div>
        <div className={clsx(`d-flex flex-stack mb-1`)}>
            {
                dataInfo &&
                    <div className="item-title fw-bold d-flex flex-stack w-100">
                        <span>
                            <i className="text-reset me-2 bi bi-person-vcard"></i>{dataInfo.name}
                            {
                                membercontacts &&
                                <span className="text-primary">{ ` - ${membercontacts.clubCode} (${membercontacts.schemeCode})`}</span>
                            }
                        </span>
                        {
                            membercontacts &&
                                <div>
                                    <span className="text-danger me-2">{formatCurrent(+membercontacts?.balance)} {t('order-deep.point')}</span>
                                    {
                                        funcCalculateBasket &&
                                        <span onClick={clearMember}><i className="bi bi-trash3 text-reset fs-4 cursor-pointer"></i></span>
                                    }
                                </div>
                        }
                    </div>
            }
        </div>
        <div className={clsx(`d-flex mb-1`)}>
            {
                note && <div className="border border-dashed bg-light-primary rounded-2 p-1 px-2 fs-7">{note}</div>
            }
        </div>
        {
            props?.einvoice && props?.einvoice?.SINBuyerCompanyName &&
            <div className="d-flex flex-stack gap-2">
                <span>
                    <i className="bi bi-buildings me-2 text-primary fs-5"></i>{props?.einvoice?.SINBuyerCompanyName} - {props.einvoice?.SINBuyerTaxNo}
                </span>
                {
                    funcCalculateBasket &&
                    <span onClick={e=>clearEInvoice()}><i className="bi bi-trash3 text-reset fs-4 cursor-pointer"></i></span>
                }

            </div>
        }
    </div>
}
export const PromotionAndSum = (props: any) => {
    const { t } = useTranslation()
    // console.log('PromotionAndSum',props)
    const {paidPayment,funcCallback} = props
    
    // console.log('paidPayment',paidPayment)
    let data = caculPromo(props.HospTransactionLines, props)
    let totalPaid = 0
    if(paidPayment) {
        totalPaid = paidPayment.filter((i: any) => !i.ischangeline).reduce(function (acc:any, obj:any) { return acc + +obj.value }, 0)
    }
    // console.log('data',data)
    const balanceTotal = () => {
        return +data.grossamount - +totalPaid
    }
    const removePaid = async(index: any) => {
        if(funcCallback) {
            funcCallback(index)
        }
    }
    const returnSubtotal = () => {
        let tempTotaldiscount = data.linediscount + +props.totaldiscount
        let tempGrossamount = data.grossamount
        return +tempGrossamount + +tempTotaldiscount

    }
    
    return <div className={clsx("d-flex gap-4 px-3",{"":data})}>
        <div className="w-100">
            <div className="d-flex flex-stack gap-2 ">
                <div className="item-title fw-bold">{t('order-deep.subtotal')}</div>
                    <span className="fs-4">{formatCurrent(returnSubtotal()?returnSubtotal():data.subtotal)}</span>
                </div>
                {/* <div className="d-flex flex-stack gap-2 text-gray-400">
                    <div className="item-title fw-bold">Tax</div>
                    <span className="fs-4">{formatCurrent(data.taxamount)}</span>
                </div> */}
                <div className="d-flex flex-stack gap-2 text-gray-700">
                    <div className="item-title fw-bold">{t('order-deep.discount')}</div>
                    <span className="fs-4">{+data.linediscount ? '-': ''}{formatCurrent(data.linediscount)}</span>
                </div>
                
                <div className={clsx(`d-flex flex-stack`)}>
                    <div className="item-title fw-bold fs-2">{t('order-deep.total')}</div>
                    <span className="fw-bold fs-2">{formatCurrent(data.grossamount)}</span>
                </div>
                
                {
                    paidPayment && <>
                    <div className={clsx(`d-flex flex-stack`)}>
                        <div className="item-title fw-bold">{t('payment-terminal-deep.balance-due')}</div>
                        <span className="fw-bold fs-2">{formatCurrent(balanceTotal()<0?0:balanceTotal())}</span>
                    </div>
                    {
                        paidPayment && paidPayment.map((paid: any,index: number) => (
                            <div className={clsx("d-flex flex-stack gap-2 cursor-pointer",{"opacity-0 h-0": paid.ischangeline})} onClick={e=> {
                                if(!paid.id) {
                                    removePaid(index)
                                }
                            }} key={index}>
                                <div className="item-title fw-bold">
                                    <span className=""><i className="text-danger bi bi-cash me-2"></i></span>
                                    {paid.method} {t('payment-terminal-deep.payment')}</div>
                                <span className="fs-3">{formatCurrent(paid.valueChange||paid.value)}</span>
                            </div>
                        ))
                    }
                    {
                        balanceTotal() < 0 && <div className={clsx(`d-flex flex-stack`)}>
                        <div className="item-title fw-bold">{t('payment-terminal-deep.change-due')}</div>
                        <span className="fw-bold fs-2">{formatCurrent(Math.abs(balanceTotal()))}</span>
                    </div>
                    }
                    
                    </>
                }
            
        </div>
        {/* {selected && isEdit > -1 && 
            <span className="w-20px"></span>
        } */}
    
    </div>
}
export const renderItemProduction = (itemLines: any) => {
    let a = itemLines.map((i: any) => ({id: i.id,production:i.HospItem?.productionsections?i.HospItem?.productionsections:(i.productionsections||['Other'])}))
        
    let tempDataKitchen = [] as any
    let dataKitchen = [] as any
    a.forEach((item: any) => {
        tempDataKitchen = tempDataKitchen.concat(item.production)
    })
    let b = Array.from(new Set(tempDataKitchen))
    b.forEach((item: any) => {
        let temp = {
            name: item,
            items: itemLines.filter((i: any) => {
                let product = i.HospItem?.productionsections||i.productionsections||['Other']
                return (product).includes(item)
            })
        }
        dataKitchen.push(temp)
    })
    return dataKitchen
}
export const renderDataDistinctLine = (itemLines: any, name: any,field: any) => {
    let a = itemLines.map((i: any) => ({id: i.id, key: i[field]}))
        
    let tempData = [] as any
    let dataRender = [] as any
    a.forEach((item: any) => {
        tempData.push(item['key'])
    })
    let b = Array.from(new Set(tempData))
    b.forEach((item: any) => {
        let items = itemLines.filter((i: any) => i[field] == item)
        if(items.length > 0) {
            let temp = {
                key: item,
                name: items[0][name],
                items: items
            }
            dataRender.push(temp)
        }
        
    })
    return dataRender
}
export const DetailItem = (props: any) => {
    const { t } = useTranslation()
    // console.log('props',props)
    const { register, reset,setValue,resetField,setError, handleSubmit, control, watch, formState: { errors,isSubmitting } } = useForm<any>()
    
    const {className, note,isModal, isPayment, reCallFunc,HospTransactionLines, isEdit, selected, setSelected, isBlock, discountlines, mobileTransBenefitLine, setItemLines, funcUpsert} = props
    const [TransactionLines,setTransactionLines] =  useState<any>([])
    const [transBenefitLine,setTransBenefitLine] =  useState<any>([])
    const funcHeight = () => {
        // console.log('aa')
        let element = document.querySelector('.wrap-item-add') as any
        let footer = document.querySelector('.wrap-footer-inf') as any
        let rank = 110
        if(isPayment) {
            rank = 130
        }
        if(!element) {
            element = document.querySelector('.wrap-itemdetails') as any
            footer = document.querySelector('.wrap-footerItem') as any
            rank = 175
            let slider = document.querySelector('.slider-wrap-item') as any
            if(slider) {
                element = document.querySelectorAll('.slider-wrap-item .wrap-itemdetails') as any
                rank = 200
            }
            let isHubs = document.querySelector('.wrap-hub-details') as any
            if(isHubs) {
                rank = 165
            }
            
        }
        // console.log('element',element[0])
        // console.log('rank',rank)

        if(element) {
            let h = +footer?.offsetHeight
            if(element[0]) {
                element.forEach((item: any) => {
                    // console.log('item',item)
                    item.style.setProperty('height', `calc(var(--vh, 1vh) * 100 - ${h+rank}px)`,'important');
                    item.scrollTop = item.scrollHeight;
                })
            }
            else {
                element.style.setProperty('height', `calc(var(--vh, 1vh) * 100 - ${h+rank}px)`,'important');
                element.scrollTop = element.scrollHeight;
            }
            
        }
    }
    useEffect(()=>{
        funcHeight()
    }, [note,props])

    useEffect(()=>{
        // console.log('HospTransactionLines',HospTransactionLines)
        if(HospTransactionLines) {
            let itemLines = sortByNumber(HospTransactionLines,'lineno')
            if(isBlock) {
                itemLines = renderItemProduction(itemLines)
            }
            setTransactionLines(itemLines)
            
            
            // setTimeout(() => {
            //     funcHeight()
            // },500)
        }
        else {
            setTransactionLines([])

        }
    }, [HospTransactionLines])
    // useEffect(()=> {
    //     if(mobileTransBenefitLine && mobileTransBenefitLine.length != 0) {
    //         let a = renderDataDistinctLine(mobileTransBenefitLine,'OfferDescription','OfferNo')
    //         // console.log('setTransBenefitLine',a)
    //         setTransBenefitLine(a)
    //     }
    // },[mobileTransBenefitLine])
    useEffect(()=>{
        // console.log('HospTransactionLines',HospTransactionLines)
        if(isEdit > -1) {
            setIsShowDetail(true)
        }
        else {
            setIsShowDetail(false)

        }
    },
    [isEdit])
    const [isShowDetail, setIsShowDetail] = useState(false)
    const handleClickDetail = (item: any,index: number) => {
        // let id = i.id
        // i.itemno = i.code
        // delete i.id
        // console.log('itemitemitem',item)
        let a  = selected||[]
        // console.log('selected',selected)
        if(!a.includes(item.id) || a.length == 0){
            if(!item.itemno && item.code) {
                item.itemno = item.code
            }
            // (reCallFunc ) && reCallFunc(item,index) // bỏ chọn k gọi detail
        }
        (reCallFunc ) && reCallFunc(item,index) // lúc nào cũng gọi detail
        handleClickCheck(item)
        

    }
    const handleClickCheck = (item: any) => {
        if(selected) {
            let a  = selected
            if(!a.includes(item.lineno) ){//&& item.kitchenstatus != KITCHENSTATUS.sent
                a.push(item.lineno)
                setSelected(a)
            }
            else if(true) {//item.kitchenstatus != KITCHENSTATUS.sent remove line
                handleClickRemoveCheck(item.lineno)
            }
        }
    }
    const handleClickRemoveCheck = (lineno: any) => {
        let a  = selected.filter((i: any) => i != lineno)
        setSelected(a)
    }
    const badgeItem = {
        'Sent': {
            label: t('order-deep.sent'),
            badge: 'light-primary',
        },
        'Not Sent': {
            label: t('order-deep.not-sent'),
            badge: 'light-danger',
        },
        'Hold': {
            label: t('order-deep.hold'),
            badge: 'light-warning',
        },
        'Stay': {
            label: t('order-deep.stay'),
            badge: 'light-info',
        },
    } as any
    const findDiscountLines = (order: any) => {
        return discountlines.filter((i: any) => +i.DiscountAmount !=0 && i.ItemNo == order.itemno && i.LineNoExt == order.lineno)
    }
    
    const templateItem = (order: any,index: number) => {

        return <div className={clsx("d-flex gap-2 py-2 cursor-pointer itemLineEvt px-3 border-bottom border-gray-300",
            {
                'text-decoration-line-through disabled text-danger':  (order.kitchenstatus == KITCHENSTATUS.voided),//(order.kotline && order.kotline.isvoided) ||
                'bg-light-warning': order.kitchenstatus && order.kitchenstatus != KITCHENSTATUS.notSent ,
                'bg-primary bg-opacity-20': selected && selected?.includes(order.lineno),

            })
            } data-lineno={order.lineno} 
        onClick={e => handleClickDetail(order,index)} key={index}>
        {/* <span className="number-circle">{order.quantity}</span> */}
        <div className="w-100">
            <div className="d-flex flex-stack gap-1 ">
                <div className="item-title d-flex flex-stack col-10 col-sm-10">
                    <span>{order.description} 
                        {
                            order.ispaid &&<>
                            <i className="bi bi-flag-fill text-success ms-2"></i>
                            </>
                        }
                    </span>
                    <div className="d-flex gap-1">
                        <span className="text-end">{order.quantity}</span>
                        <span className="min-w-50px text-end">{formatCurrent(order.price)}</span>
                        <span className="min-w-50px text-center text-danger">{+order.discountamount?`${formatCurrent((+order.discountamount).toFixed())}`:''}</span>
                    </div>
                </div>
                <span className="min-w-50px text-end">{formatCurrent(order.amount)}</span>
            </div>
            {
                order.customizations?.map((cus:any,key:number) =>(
                <div className="d-flex flex-stack gap-1 text-gray-700" key={key}>
                    <div className="item-title d-flex flex-stack col-10 col-sm-10">
                        <span>{cus.description}</span>
                        <div className="d-flex gap-1">
                            <span className="min-w-50px text-end">{cus.quantity}</span>
                            <span className="min-w-50px text-end">{formatCurrent(cus.price)}</span>
                            <span className="min-w-50px text-center text-danger">{+cus.discountamount ? formatCurrent((+cus.discountamount).toFixed()):''}</span>
                        </div>

                    </div>
                    <span>{formatCurrent(cus.amount)}</span>
                </div>
                ))
            }
            {
                discountlines && findDiscountLines(order)?.map((discount: any,ind: number) =>(
                    <div className="d-flex flex-stack gap-1" key={ind}>
                        <div className="item-title d-flex flex-stack col-10 col-sm-10">
                            <span className="fs-7"><i className="text-black bi bi-gift me-1 fs-7"></i>{discount.Description} <span className="badge-custom-sale bg-danger text-white ms-2 ">-{discount.DiscountPercent}%</span></span>
                            {/* <span className="text-danger">-{formatCurrent(discount.DiscountAmount)}</span> */}
                        </div>
                        <span className="text-danger"></span>
                    </div>
                ))
            }
            {
                order.message && <div>
                    <span className="badge badge-secondary p-2">{order.message}</span>
                </div>
            }
            
        </div>
    </div>
    }
    const templateItemIsBlock = (data: any,ind: number) => {
        const badge = ['info','warning','danger','primary','success','orange','gray-500','secondary']
        return <div className={clsx(`bg-opacity-20 bg-${badge[ind]}`)}>
            <h6 className={clsx(`bg-${badge[ind]} bg-opacity-50 p-3`)}>{data.name}</h6>
            {
                data.items?.map((order: any, index: number)=>{
                    return <div className="px-2">
                        {templateItem(order,index)}
                    </div>
                })
            }
        </div>
        
    }
    const addCouponLine = async(item: any) => {
        // 
        // console.log('item',item)
        try {
            let {data} = await ApiService.get(`selforders/hospitems/find/findbycode/${item.TriggerCode}`,)
            
            if(data && data.id) {
                let lineno = (props.HospTransactionLines.length+1)*1000
                let tempData = {
                    hosptransactionid: props?.id,
                    "statuscode": 1,
                    "hosptransactioncode": props.hosptransactioncode,
                    "lineno":lineno,
                    "entrytype": "Item",
                    "itemno": item.TriggerCode,
                    "itemname": item.Description,
                    "variantcode": item.VariantCode,
                    "description": item.Description,
                    "storeno": props.storecode,
                    "linewasdiscounted": false,
                    "price": 0,
                    "netprice": 0,
                    "quantity": 1,
                    "vat": 0,
                    "discount": 0,
                    "discountamount": 0,
                    "netamount": 0,
                    "vatamount": 0,
                    "amount": 0,
                    "couponqtyused": 0,
                    "coupondiscountpercent": 0,
                    "coupondiscountamount": 0,
                }
                let lineWithCoupon = HospTransactionLines.concat([tempData])
                lineWithCoupon.map((i: any,index: number) =>{
                    let lineno = (index+1)*1000
                    if(i.id) {
                        lineno = i.lineno
                    }
                    else if(lineWithCoupon.find((i: any) => i.id).length !=0) {
                        let max = Math.max(...lineWithCoupon.map((o: any) => o.lineno)) 
                        lineno = max+1000
                    }
                    if(!item.id) {
                        lineno = (index+1)*1000
                    }
                    let customizations = i.customizations?.map((cus: any,ind: number) =>({...cus,lineno: lineno+ind+1}))||[]
                    
                    return {...i,customizations: customizations, lineno: lineno}
                })
                let temp = {
                    id: props?.id,
                    "HospTransactionLines": lineWithCoupon,
                }
                // console.log('tempData',tempData)
                funcUpsert && funcUpsert(temp)
                setTransBenefitLine([])
                // let {data} = await ApiService.post(`smarthub/hosptransactionlines`,tempData)
            
                // if(data && data.length != 0) {
                //     popupMessage({ icon: 'success', autoClose: true })
                // }
                // else {
                //     popupMessage({icon:'info',description: 'Not found coupon!'})
                // }
                // let a = TransactionLines
                // setTransactionLines()
            }
            else {
                popupMessage({icon:'info',description: t('order-deep.not-found-item!') })
            }

            
            // let a = 
            // setItemLine()
           
            
        } catch (error) { ApiShowError(error) }
    }
    return <div>
    <div className="px-3">
        <div className="d-flex flex-stack"> 
            <span>#{props.code} <i className="ms-2 me-1 text-reset bi bi-clock"></i>{new Date(props.transactiondate).toLocaleDateString('GB-en')}, {props.transactiontime} </span>
            <span>{props.saletype}</span>
        </div>
        
        {/* {props.receiptno && <div className="d-flex flex-stack"> 
            <span>Receipt No.: {props.receiptno} </span>
        </div>} */}
        <div className="d-flex flex-stack line-item">
            <span><i className="bi bi-person-hearts me-2"></i>{props?.staffcode}</span>
            
            {
                props.noofcovers && <span>{props.noofcovers} {t('orders-hub-deep.guest')}</span>
            }
        </div>
        <div className="d-flex flex-stack gap-1 ">
            <div className="item-title text-black d-flex flex-stack col-10 col-sm-10 fw-bold">
                <span>{t('order-deep.description')}</span>
                <div className="d-flex gap-1">
                    <span className="text-end">{t('order-deep.qty')}</span>
                    <span className="min-w-50px text-end">{t('order-deep.price')}</span>
                    <span className="min-w-50px text-center">{t('order-deep.disc')}</span>
                </div>
            </div>
            <span className="min-w-50px text-end fw-bold text-nowrap">{t('order-deep.amount')}</span>
            
        </div>
    </div>
    <div className={clsx(`border-bottom border-gray-400 border-top bg-gray-200 overflow-y-auto mb-2 ${className?className : ''} ${(isModal||isPayment) ? 'wrap-item-add':'wrap-itemdetails'} wrap-item-line${isModal ? '-modal':''}${isPayment ? '-payment':''}${(note) ? '-with-cmt':''}${( props?.customerinfo?.cardNo) ? '-member':''}`,)} >
    {
        TransactionLines && TransactionLines.length != 0 ? TransactionLines?.map((order: any,index: number) => {
            return isBlock ? templateItemIsBlock(order,index) : templateItem(order,index) 
        })
        :
        <div className="py-5 px-3 bg-light-warning my-3"><i>{t('order-deep.please-select-item!')}</i></div>
    }
    {
        transBenefitLine.length != 0 && transBenefitLine.map((fit: any,index: number) =>(
            <div className=" px-3 my-3" key={index}>
                <h5 className="fs-7"><i className="text-black bi bi-gift me-1 fs-7"></i>{fit.name}</h5>
                <div className="ps-5">
                {
                    fit.items?.map((be: any,ind: number) =>(
                        <div className="d-flex flex-stack gap-1" key={ind}>
                            <div className="item-title d-flex align-items-baseline gap-5 col-10 col-sm-10">
                                <span className="mb-3">
                                    <input  className={clsx("form-check-sm form-check-input me-3",)} type="radio" {...register('triggerCode')} value={be.TriggerCode} onClick={e=> {
                                        // addCouponLine(be)
                                    }} id={`type_${ind}`} />
                                    <label className="cursor-pointer" htmlFor={`type_${ind}`}>{be.Description}</label>
                                </span>
                                {
                                    watch('triggerCode') == be.TriggerCode &&
                                    <span onClick={e=> addCouponLine(be)} className="text-primary d-flex flex-center gap-1 cursor-pointer">
                                        <span className="text-primary"><i className="bi bi-plus-circle text-primary"></i></span>
                                        <span>{t('orders-hub-deep.add')}</span>
                                    </span>
                                }
                                
                            </div>
                            <span className="text-danger"></span>
                        </div>
                    ))
                }
                </div>
                
            </div>
        ))
    }
    </div>
    
    </div>
    
    
}
export const NoDataTemplate = (props?: any) => {
    const { t } = useTranslation()
    let {className} = props
    return <div className={clsx(`card-body pb-0 h-100 text-center ${className?className:''}`,)}>
    <div className="bg-white h-100 d-flex flex-center">
        <div>
            <i className="bi bi-basket3 fs-9x m-auto"></i>
            <div>{t('order-deep.no-orders')}</div>
        </div>
    </div>
</div>
}
export const funcAddNote = async(message: any,setNote: any,ids: any, id: any) => {
    try {
        // console.log('ids',ids)
        // if(ids && ids.length != 0) {
        //     let {data} = await ApiService.put(`smarthub/kots/updatemulti/updatecomments`,{comments: message,ids:ids})
        // }
        // else {
        //     let {data} = await ApiService.put(`smarthub/hosptransactions/updatemessage/${id}`,{message: message})
        // }
        let {data} = await ApiService.put(`smarthub/hosptransactions/updatemessage/${id}`,{message: message})
        setNote(message)

    }catch (error) { ApiShowError(error) }

}
export const ModalMessage = (props: any) => {
    const { t } = useTranslation()
    const {isShowNote,setIsShowNote, callFuncAddMess, setNote,ids, idHosp} = props
    const { register, reset,setValue,resetField,setError, handleSubmit, control, watch, formState: { errors,isSubmitting } } = useForm<any>()
    const onSubmitNote = (data: any) => {
        callFuncAddMess(data.note,setNote,ids, idHosp)
        setIsShowNote(false)
    }
    useEffect(()=>{
       

    },[isShowNote])
    return <Modal show={isShowNote} keyboard={false} className="backdrop-filter-blur-50" onHide={() => setIsShowNote(false)}>
    <Modal.Body className="bg-gray-200 rounded">
      <form onSubmit={handleSubmit(onSubmitNote)} className="row">
        <KTFormItem title={t('order-deep.note')} className="mb-4" isRequired isBLockLabel>
            <textarea autoFocus={true} {...register('note')} className="form-control form-control-sm " rows={3}/>
        </KTFormItem>
        <div className="mb-4">
            <div className="mb-4"><strong>{t('order-deep.quick-note')}:</strong></div>
            <div className="d-flex gap-4 flex-wrap">
            {
                arrNote.map((i: any, index: number) =>(<span key={index} onClick={e=>{
                    if(watch('note')) {
                        setValue('note',[watch('note'),i.name].join(', '))
                    }
                    else {
                        setValue('note',i.name)
                    }
                }} className="min-w-100px cursor-pointer bg-white btn btn-sm border border border-dark-subtle text-dark">{i.name}</span>))
            }
            </div>
        </div>
        <div className='text-end d-flex gap-4 flex-end'>
          <span onClick={e=>{
            setIsShowNote(false)
            setValue('note','')
          }} className="btn btn-sm btn-secondary w-100px min-w-100px ">{t('orders-hub-deep.cancel')}</span>
          <button className="btn btn-sm btn-primary w-100px min-w-100px " disabled={!watch('note')}>{t('orders-hub-deep.submit')}</button>
        </div>
      </form>
    </Modal.Body>
  </Modal>
}

export const ModalChangeCustomer = (props: any) => {
    const { t } = useTranslation()
    const {isShow,setIsShow, setValueData, idHosp, dataModal,funcCalculateBasket} = props
    const { register, reset,setValue,resetField,setError, handleSubmit, control, watch, formState: { errors,isSubmitting } } = useForm<any>()
    const onSubmit = async(record: any) => {
       
        let apiCurrent = `lscentral/membercontacts/findbymobilephoneno/${record.phoneNoOrCardNo}`

        if(record.type != 'mobilePhoneNo') {
            apiCurrent = `lscentral/membercontacts/findbycardno/${record.phoneNoOrCardNo}`
        }
        try {
            popupLoading()
            let {data} = await ApiService.get(apiCurrent)
            if(data && data.length !=0){
                let tempData = {
                    clubCode: data[0]?.clubCode,
                    cardNo: data[0]?.cardNo,
                    mobilePhoneNo: data[0]?.mobilePhoneNo,
                    name: data[0]?.name,
                    balance: data[0]?.balance
                }
                let dataUpdate = await ApiService.put(`smarthub/hosptransactions/updatecustomerinfo/${idHosp}`,{customerinfo:tempData})
                if(dataUpdate.data) {
                    let customerinfo = dataUpdate.data?.customerinfo
                    let message = dataUpdate.data?.message
                    let data = JSON.parse(JSON.stringify(dataModal))
                    data.customerinfo = customerinfo
                    if(message) {
                        data.message = message
                    }
                    setValueData(data)
                    funcCalculateBasket(data)
                }
                setIsShow(false)
                popupMessage({ icon: 'success', autoClose: true })
            }
            else {
                popupMessage({icon:'info',description: 'Not found member!'})
            }
            
        } catch (error) { ApiShowError(error) }
    }
    useEffect(()=>{
        if(isShow) {
            setValue('type',"mobilePhoneNo")
            setValue('phoneNoOrCardNo',"")
        }
    },[isShow])
    const optionsType = [
        {
            label:t('order-deep.phone-number'),
            value: "mobilePhoneNo",
        },
        {
            label:t('order-deep.card-number'),
            value: "cardNo",
        },
    ]
    return <Modal show={isShow} keyboard={false} aria-hidden="true" className="backdrop-filter-blur-50" onHide={() => setIsShow(false)}>
    <Modal.Body className="bg-gray-200 rounded">
      <form onSubmit={handleSubmit(onSubmit)} className="row">
        <KTFormItem title={t('order-deep.type')} labelWidth="150" className="mb-4" isRequired>
            <div className="d-flex flex-stack gap-4">
                <div className="form-check form-check-sm form-check-custom form-check-solid flex-stack cursor-pointer">
                  <input className="form-check-input me-3" type="radio" {...register('type')} value='mobilePhoneNo'  id='check1' />
                  <label htmlFor="check1">{t('order-deep.phone-number')}</label>
                </div>
                <div className="form-check form-check-sm form-check-custom form-check-solid flex-stack cursor-pointer">
                  <input className="form-check-input me-3" type="radio" {...register('type')} value='cardNo' id='check2' />
                  <label htmlFor="check2">{t('order-deep.card-number')}</label>
                </div>
            </div>
          
        </KTFormItem>
        <KTFormItem title={`${t('order-deep.phone-number')}/${t('order-deep.card-number')}`} labelWidth="150" className="mb-4" isRequired>
            <input autoFocus {...register('phoneNoOrCardNo')} className="form-control form-control-sm " />
        </KTFormItem>
        <div className='text-end d-flex gap-4 flex-end'>
          <span onClick={e=>{
            setIsShow(false)
            setValue('phoneNoOrCardNo','')
          }} className="btn btn-sm btn-secondary w-100px min-w-100px ">{t('orders-hub-deep.cancel')}</span>
          <button className="btn btn-sm btn-primary w-100px min-w-100px " disabled={!watch('phoneNoOrCardNo')}>{t('order-deep.apply')}</button>
        </div>
      </form>
    </Modal.Body>
  </Modal>
}
export const ModalAddPromotion = (props: any) => {
    const { t } = useTranslation()
    const {isShow,setIsShow, setValueData, idHosp, onSubmitFunc,dataModal} = props
    const { register, reset,setValue,resetField,setError, handleSubmit, control, watch, formState: { errors,isSubmitting } } = useForm<any>()
    const onSubmit = async(record: any) => {
        try {
            popupLoading()
            let {data} = await ApiService.get(`lscentral/couponentries/findbybarcode/${record.barcode}`,)
            setIsShow(false)
            
            if(data && data.length != 0) {
                popupMessage({ icon: 'success', autoClose: true })
                setValueData(data)
                onSubmitFunc && onSubmitFunc(dataModal,data)
            }
            else {
                popupMessage({icon:'info',description: t('order-deep.not-found-coupon!')})
            }
            
        } catch (error) { ApiShowError(error) }
    }
    useEffect(()=>{
        if(isShow) {
            setValue('barcode',"")
        }
    },[isShow])
   
    return <Modal show={isShow} keyboard={false} aria-hidden="true" className="backdrop-filter-blur-50" onHide={() => setIsShow(false)}>
    <Modal.Body className="bg-gray-200 rounded">
      <form onSubmit={handleSubmit(onSubmit)} className="row">
        <KTFormItem title={t('order-deep.coupon-code')} labelWidth="120" className="mb-4" isRequired>
            <input autoFocus {...register('barcode')} className="form-control form-control-sm " />
        </KTFormItem>
        <div className='text-end d-flex gap-4 flex-end'>
          <span onClick={e=>{
            setIsShow(false)
            setValue('barcode','')
          }} className="btn btn-sm btn-secondary w-100px min-w-100px ">{t('orders-hub-deep.cancel')}</span>
          <button className="btn btn-sm btn-primary w-100px min-w-100px " disabled={!watch('barcode')}>{t('order-deep.apply')}</button>
        </div>
      </form>
    </Modal.Body>
  </Modal>
}
export const ModalAddMST = (props: any) => {
    const { t } = useTranslation()
    const {isShow,setIsShow, setValueData,dataModal} = props
    let schema = {
        SINBuyerTaxNo: yup.string()
          .required('Input is required'),
        SINBuyerCompanyName: yup.string()
          .required('Input is required'),
        SINBuyerCompanyAddress: yup.string()
          .required('Input is required'),
       
      }
    const yupSchema = yup.object().shape(schema)

    const { register, reset,setValue,resetField,setError, handleSubmit, control, watch, formState: { errors,isSubmitting } } = useForm({ resolver: yupResolver(yupSchema) })
    const lookupTax = async() => {
        if(!watch('SINBuyerTaxNo')) {
            return
        }
        popupLoading()
        let {data} = await ApiService.get(`externals/einvoiceservice/lookuptaxcode/${watch('SINBuyerTaxNo')}`)
        popupMessage({ icon: 'success', autoClose: true })
        // console.log('data',data)
        if(data && data.SoapBody) {
            setValue('SINBuyerCompanyName',data.SoapBody.TenChinhThuc)
            setValue('SINBuyerCompanyAddress',data.SoapBody.DiaChiGiaoDich)
        }
    }
    const onSubmit = async(record: any) => {
        try {
            // popupLoading()
            let tempData = {
                einvoice: {
                    ...record
                }
            }
            let {data} = await ApiService.put(`smarthub/hosptransactions/updatecustomerinfo/${dataModal?.id}`,tempData)
            let temp = {
                ...dataModal,
                einvoice: record
            } 
            console.log('temp',temp)
            
            setValueData(temp)
            setIsShow(false)
            
            
            
        } catch (error) { ApiShowError(error) }
    }
    const resetData = () =>{
        setValue('SINBuyerTaxNo','')
        setValue('SINBuyerCompanyName','')
        setValue('SINBuyerCompanyAddress','')
        setValue('SINBuyerPhoneNo','')
        setValue('SINBuyerEmail','')
    }
    useEffect(()=>{
        if(isShow) {
            if (dataModal.einvoice) {
                reset(dataModal.einvoice)
            }
            else {
                resetData()
            }
            // console.log('resetData')
        }
    },[isShow])
   
    return <Modal show={isShow} keyboard={false} aria-hidden="true" backdrop="static" className="backdrop-filter-blur-50" onHide={() => setIsShow(false)}>
    <Modal.Header className="p-4 border-bottom border-gray-300">
            <h5 className="fs-3" id="offcanvasLabel">{t('order-deep.e-invoice')}</h5>
            <button type="button" className="btn-close text-reset" onClick={e=>setIsShow(false)}  aria-label="Close"></button>
    </Modal.Header>
    <Modal.Body className="bg-gray-200 rounded">
        
      <form onSubmit={handleSubmit(onSubmit)} className="row">
        <KTFormItem title={t('order-deep.tax-code')} labelWidth="120" isBLockLabel className="mb-4" isRequired>
            <div className="d-flex flex-stack gap-3">
                <input autoFocus {...register('SINBuyerTaxNo')} className={clsx("form-control form-control-sm",{ 'form-error': errors.SINBuyerTaxNo})} />
                <span onClick={lookupTax} className="btn btn-sm btn-primary text-nowrap">{t('order-deep.query')}</span>
            </div>
        </KTFormItem>
        <KTFormItem title={t('order-deep.company-name')} labelWidth="120" isBLockLabel className="mb-4" isRequired>
            <input  {...register('SINBuyerCompanyName')} className={clsx("form-control form-control-sm",{ 'form-error': errors.SINBuyerCompanyName})} />
        </KTFormItem>
        <KTFormItem title={t('order-deep.company-address')} labelWidth="120" isBLockLabel className="mb-4" isRequired>
            <textarea  {...register('SINBuyerCompanyAddress')} className={clsx("form-control form-control-sm",{ 'form-error': errors.SINBuyerCompanyAddress})} ></textarea>
        </KTFormItem>
        <KTFormItem title={t('order-deep.phone-no')} labelWidth="120" isBLockLabel className="mb-4" >
            <input  {...register('SINBuyerPhoneNo')} className={clsx("form-control form-control-sm",{ 'form-error': errors.SINBuyerPhoneNo})} />
        </KTFormItem>
        <KTFormItem title={t('order-deep.email')} labelWidth="120" isBLockLabel className="mb-4" >
            <input  {...register('SINBuyerEmail')} className={clsx("form-control form-control-sm",{ 'form-error': errors.SINBuyerEmail})} />
        </KTFormItem>
        <div className='text-center'>
          
          <button className="w-100 btn btn-sm btn-primary " >{t('order-deep.done')}</button>
        </div>
      </form>
    </Modal.Body>
  </Modal>
}
export const ModalAddGuest = (props: any) => {
    const { t } = useTranslation()
    const {isShow,setIsShow, setValueData, idHosp, valueData} = props
    const { register, reset,setValue,resetField,setError, handleSubmit, control, watch, formState: { errors,isSubmitting } } = useForm<any>()
    const onSubmit = async(record: any) => {
        // callFuncAddMess(data.note,setNote,ids, idHosp)
       let {data} = await ApiService.put(`smarthub/hosptransactions/updatenoofcovers/${idHosp}`,{noofcovers : record.noofcovers})
       setValueData(record.noofcovers)
       setIsShow(false)
    }
    useEffect(()=>{
        setValue('noofcovers',valueData)
    },[isShow])
    return <Modal show={isShow} keyboard={false} className="backdrop-filter-blur-50" onHide={() => setIsShow(false)}>
    <Modal.Body className="bg-gray-200 rounded">
      <form onSubmit={handleSubmit(onSubmit)} className="row">
        <KTFormItem title={t('order-deep.number-of-guest')} className="mb-4" isRequired isBLockLabel>
            <input type="number" autoFocus {...register('noofcovers')} className="form-control form-control-sm " />
        </KTFormItem>
        <div className='text-end d-flex gap-4 flex-end'>
          <span onClick={e=>{
            setIsShow(false)
            setValue('noofcovers','')
          }} className="btn btn-sm btn-secondary w-100px min-w-100px ">{t('orders-hub-deep.cancel')}</span>
          <button className="btn btn-sm btn-primary w-100px min-w-100px " disabled={!watch('noofcovers')}>{t('orders-hub-deep.submit')}</button>
        </div>
      </form>
    </Modal.Body>
  </Modal>
}
export const ModalPay = (props: any) => {
    const { t } = useTranslation()
    const {show, setShow, dataModal, refetchCurrent,handleCloseModal} = props
    const [valuePrice,setValuePrice] = useState<any>('0')
    const [tendertype,setTendertype] = useState<any>('Cash')
    let staffInfo = useStoreMode((e: any)=> e.info)?.staffInfo || {}
    let staffPermissionGroupInfo = useStoreMode((e: any)=> e.info)?.staffPermissionGroupInfo || {}
    const [arrPayment,setArrPayment] = useState<any>([])
    const amounttendered = () => {
        if(dataModal && dataModal.HospTransactionLines) {
            if(dataModal.TransactionPaymentEntries && dataModal.TransactionPaymentEntries.length != 0) {
                return (dataModal.TransactionPaymentEntries.reduce(function (acc: any, obj: any) { return acc + +obj.amounttendered; }, 0))

            }
        }
        return 0
    }
    const totalItemsPay = () => {
        let a = dataModal?.grossamount - amounttendered()
        if (a < 0) {
            a = 0
        }
        return a
    }
    useEffect(() => {
        setArrPayment([])
        setValuePrice(0)
        if(show) {
            if(dataModal && dataModal.HospTransactionLines) {
                if(dataModal.TransactionPaymentEntries && dataModal.TransactionPaymentEntries.length != 0) {
                    let temp = [] as any
                    dataModal.TransactionPaymentEntries.forEach((i: any) => {
                        let a = {
                            method: i.tendertype,
                            value: i.amounttendered,
                            id: i.id,
                            ischangeline: i.ischangeline,
                        }
                        temp.push(a)
                    })
                    setArrPayment(temp)
    
                }
            }
            
            // formatSuggestAmounts()
        }
        else {
            setSuggestAmounts([null,null,null])
        }
    },[show])
    const handleCloseModalPay = () => {
        setSuggestAmounts([null,null,null])
        setShow(false)
    }
    const handleNumber = (str: string) =>{
        if(isResetCaculator) {
            setValuePrice(str)
            setIsResetCaculator(false)
            return
        }
        setValuePrice(valuePrice+str)
    }
    const [isResetCaculator,setIsResetCaculator] = useState(false)
    const handleReplaceNumber = (str: string) =>{
        setIsResetCaculator(true)
        setValuePrice(str)
    }
    const handleSplitNumber = () =>{
        setValuePrice(valuePrice.slice(0, -1))
    }
    const changeStatusHosptransactions = async(status: any) => {
        let tempApproved = {
            transactionstatus: status,
            staffcode: staffInfo.code,
            staffreceiptname: staffInfo.nameonreceipt,
            HospTransactionLines : dataModal?.HospTransactionLines?.filter((i: any) => i.kitchenstatus == 'Not Sent').map((i: any) => {
                return {
                    ...i
                }
            })
        }
        try {
            const urlApi = `smarthub/hosptransactions/update/${dataModal.id}`
            await ApiService.put(urlApi, tempApproved)
        } catch (error) { ApiShowError(error) }
    }
    const funcPayment = async(valuePrice: any) => {
        // console.log('item',valuePrice, dataModal,staffInfo)
        // console.log('arrPayment',arrPayment)
        let sumLineno = 0
        let paymentEntries = dataModal.TransactionPaymentEntries||dataModal.PaymentEntries
        if(paymentEntries) {
            sumLineno = paymentEntries.length
        }
        let tempData = arrPayment.filter((i: any) => !i.id).map((i: any,index: number) => (
            {
                "transactionid": dataModal.id,
                "transactioncode": dataModal.code,
                "lineno": sumLineno+index+1,
                "merchantcode": dataModal.merchantcode,
                "amounttendered": i.ischangeline? -+i.value:+i.value,//Số tiền khách đưa
                "staffcode": staffInfo.code,
                tendertype: i.method, //Hình thức thanh toán: CASH,PAYOO,MOMO,VNPAY,GIFTCARD,COUPON
                tendertypename: i.method, //Tiền mặt, Payoo, Momo, VNPay, Thẻ quà tặng, Coupon
                ischangeline: i.ischangeline, //có nhận tiền thối lại, nếu true thì amounttendered là số âm
                ismanagerskey: staffPermissionGroupInfo.ismanagerprivileges //giao dịch có sử dụng quyền manager
            }
        ))
        let index = arrPayment.length - 1
        if(arrPayment[index]) {

        }
        let a = arrPayment[arrPayment.length - 1]
        // return
        // try {
            const urlApi = `smarthub/transactionpaymententries/createmulti`
            await ApiService.post(urlApi, tempData)
            
            // callBackReFetch()
            // tempData = tempData.filter((i: any,ind: number) => ind != orderActive)
            // setDataQuery(tempData)
            setShow(false)
            refetchCurrent && refetchCurrent()
            popupMessage({ icon: 'success', autoClose: true })
            handleCloseModal && handleCloseModal()
            // popupMessageHTMl({icon:'success', showConfirmButton: false, timer: 1500, showCloseButton: false, text: `${formatCurrent(dataModal.grossamount)} VNĐ`, title: "Thank you!"} as any)

        // } catch (error) { ApiShowError(error) }
    }
    const actionDonePay = async() => {
        funcPayment(valuePrice)
    }
    const sumTotalPaid = () => {
        let a = arrPayment.filter((i: any) => !i.ischangeline).reduce(function (acc:any, obj:any) { return acc + (+obj.valueChange||+obj.value) }, 0)
        return a
    }

    const checkSubmit = () =>{
        return +dataModal?.grossamount - +sumTotalPaid()  > 0
    }
    useEffect(() => {
        setValuePrice(numShowBalance())
        setIsResetCaculator(true)
        if(show) {
            formatSuggestAmounts()
        }
    },[arrPayment])
    const methodPaymentApply = (method: any) => {
        setTendertype(method)
        let temp = {
            method: method,
            value: valuePrice,
            valueChange: valuePrice,
            ischangeline: false,
        }
        let a = JSON.parse(JSON.stringify(arrPayment))
        // console.log('valuePrice',valuePrice)
        if(valuePrice) {
            a.push(temp)
            let sum = a.filter((i: any) => !i.ischangeline).reduce(function (acc:any, obj:any) { return acc + (+obj.valueChange||+obj.value) }, 0)
            let totalPay = +dataModal?.grossamount - sum
            // console.log('totalPay',totalPay)
            if(totalPay < 0 ) {
                let ind = a.length - 1
                a[ind].value = +dataModal?.grossamount - +sumTotalPaid()
                if(method != 'Momo') {
                    let tempb = {
                        method: method,
                        value: Math.abs(totalPay),
                        ischangeline: true,
                    }
                    a.push(tempb)
                    setIsChangeDue(true)
                }
                // else {
                //     let ind = a.length - 1
                //     a[ind].value = +dataModal?.grossamount - +sumTotalPaid()
                // }
            }
            setArrPayment(a)
        }
        // console.log('balanceTotal()',balanceTotal())
        

    }
    const balanceTotal = () => {
        return +dataModal?.grossamount - sumTotalPaid()
    }
    const [isChangeDue,setIsChangeDue] = useState(false)
    const deletePaid = async(index: any) => {
        let aws = await popupQuestion({title: t('orders-hub-deep.are-you-sure-you-delete-this-paid?')})
        if(aws) {
            let a = arrPayment.filter((i: any,ind: number) => ind != index)
            setArrPayment(a)
        }
        
    }
    const numShowBalance = () => {
        return balanceTotal() < 0 ? 0 : balanceTotal()
    }
    const [suggestAmounts,setSuggestAmounts] = useState<any>([null,null,null])
    function formatSuggestAmounts() {
        // console.log('aaaaaaaaaaaaaaaaaaaaaaaaa',suggestAmounts)
        let orderAmount = numShowBalance()||0//+dataModal?.grossamount||0 // //
        // console.log('orderAmount',orderAmount)
        if(suggestAmounts[0]) {
            return 

        }
        // Mảng chứa các mệnh giá tiền
        // console.log('aaaaaaaaaaaaaaaaa')

        const denominations = [50000, 100000, 200000, 500000];
        // Mảng chứa các số tiền đề nghị
        const suggestions = [];

        // Làm tròn số đầu tiên lên đến bội số của 10,000 VNĐ
        let firstSuggestion = Math.ceil(orderAmount / 10000) * 10000;
    
        // Thêm số tiền chẵn đầu tiên vào danh sách đề nghị
        suggestions.push(firstSuggestion);
    

        // Tìm hai số tiền tiếp theo theo mệnh giá tiền và thêm vào danh sách đề nghị
        for (let i = 0; i < denominations.length; i++) {
            let nextAmount: any = Math.floor((firstSuggestion + denominations[i])/50000)*50000;
            if (!suggestions.includes(nextAmount) && nextAmount > orderAmount) {
                suggestions.push(nextAmount);
                break;
            }
        }

        // Nếu cần, thêm các số tiền tiếp theo theo mệnh giá tiền
        while (suggestions.length < 3) {
            let lastAmount: any = suggestions[suggestions.length - 1];
            for (let i = 0; i < denominations.length; i++) {
                let nextAmount = lastAmount + denominations[i];
                if (!suggestions.includes(nextAmount)) {
                    suggestions.push(nextAmount);
                    if (suggestions.length === 3) {
                        break;
                    }
                }
            }
        }
        // console.log('bbbbbbbbbb')
        setSuggestAmounts(suggestions)
    }
   
    return <>
    <Modal show={isChangeDue} fullscreen={true} className="popup-in-popup" onHide={() => setIsChangeDue(false)}>
        <Modal.Body className="d-flex flex-center fs-1">
            <div className="w-50">
                <div className="text-center">
                    <div className="">{t('payment-terminal-deep.change-due')}</div>
                    <div className="fs-3x">{formatCurrent(Math.abs(balanceTotal()))}</div>
                </div>
                <div className="d-flex flex-stack">
                    <span>{t('payment-terminal-deep.balance-due')}:</span>
                    <span>{formatCurrent(dataModal?.grossamount)}</span>
                </div>
                <div className="d-flex flex-stack">
                    <span>{t('payment-terminal-deep.tendered-amount')}:</span>
                    <span>{formatCurrent(sumTotalPaid())}</span>
                </div>
                <div className="text-center pt-5">
                    <button className="btn btn-secondary me-3 m-auto" onClick={() => {
                        setIsChangeDue(false)
                        let a = arrPayment.filter((i: any,ind: number) => ind != arrPayment.length - 1)
                        setArrPayment(a)
                    }}>{t('orders-hub-deep.cancel')}</button>
                    <button className="btn btn-primary m-auto" onClick={() => setIsChangeDue(false)}>{t('orders-hub-deep.submit')}</button>
                </div>
            </div>
            
        </Modal.Body>
    </Modal>
    <Modal show={show} fullscreen={true} onHide={() => handleCloseModalPay()}>
    {
        dataModal && 
        <Modal.Body className="bg-gray-200 p-2">
    <div className="row">
        <div className="col-sm-4 col-12">
            <div className="p-2 card shadow">
                <h6  className="text-black fs-3 d-flex flex-stack px-3 pt-3">
                    <span>
                        <i onClick={e=>handleCloseModalPay()} className="bi bi-arrow-left fs-3 text-black p-3 ps-0 cursor-pointer"></i>
                        #{dataModal?.code} {dataModal.sectionname}
                    </span>
                    <div className="d-flex gap-2 flex-center"> 
                        {dataModal.receiptno && 
                            <span className="">{dataModal.receiptno} </span>
                        }
                    </div>
                </h6>
             
                <DetailItem note={dataModal.message} {...dataModal} isPayment={true}/>
                <div className="wrap-footer-inf">
                    <h3 className=" pt-2 border-gray-400">{t('payment-terminal-deep.payment')}</h3>
                    <PromotionAndSum {...dataModal} paidPayment={arrPayment}  funcCallback={deletePaid}/> 
                    <div className="mt-4">
                        <button disabled={checkSubmit()} onClick={actionDonePay} className="btn btn-sm btn-primary w-100"><i className="bi bi-check2 me-1"></i>{t('order-deep.done')}</button>
                    </div>
                </div>
                
            </div>
        </div>
        <div className="col-sm-8 col-12">
            <div className="row">
                <div className="col-7">
                    <div className=" d-flex flex-stack gap-3">
                        <span className="fs-3x">{t('payment-terminal-deep.balance-due')} {formatCurrent(numShowBalance())}</span>
                        <span className=" fs-2 text-gray-600">{t('payment-terminal-deep.amount-tendered')}</span>
                    </div>
                    <div className="d-flex flex-stack gap-3 mb-3">
                        <button onClick={e => handleReplaceNumber(numShowBalance().toString())} className="btn btn-icon btn-secondary fs-5 w-200px">{formatCurrent(numShowBalance())}</button>
                        <InputPrice value={valuePrice}
                                    onChange={(e: any)=> setValuePrice(e)} className={`${'form-control fs-3 w-100 text-end'}`} />
                        {/* <input className={clsx('form-control w-100 text-end', { 'form-error': errors.ses_classid })} {...register('ses_classid', {})} /> */}
                    </div>
                    <div className="d-flex flex-stack gap-3 mb-3">
                        <button onClick={e => handleNumber('1')} className="btn btn-icon btn-secondary p-10 fs-1">1</button>
                        <button onClick={e => handleNumber('2')} className="btn btn-icon btn-secondary p-10 fs-1">2</button>
                        <button onClick={e => handleNumber('3')} className="btn btn-icon btn-secondary p-10 fs-1">3</button>
                        <button onClick={e => handleReplaceNumber(suggestAmounts[0]?.toString())} className="btn btn-gray w-100 h-60px fs-1">{formatCurrent(suggestAmounts[0])}</button>
                    </div>
                    <div className="d-flex flex-stack gap-3 mb-3">
                        <button onClick={e => handleNumber('4')} className="btn btn-icon btn-secondary p-10 fs-1">4</button>
                        <button onClick={e => handleNumber('5')} className="btn btn-icon btn-secondary p-10 fs-1">5</button>
                        <button onClick={e => handleNumber('6')} className="btn btn-icon btn-secondary p-10 fs-1">6</button>
                        <button onClick={e => handleReplaceNumber(suggestAmounts[1]?.toString())} className="btn btn-gray w-100 h-60px fs-1">{formatCurrent(suggestAmounts[1])}</button>
                    </div>
                    <div className="d-flex flex-stack gap-3 mb-3">
                        <button onClick={e => handleNumber('7')} className="btn btn-icon btn-secondary p-10 fs-1">7</button>
                        <button onClick={e => handleNumber('8')} className="btn btn-icon btn-secondary p-10 fs-1">8</button>
                        <button onClick={e => handleNumber('9')} className="btn btn-icon btn-secondary p-10 fs-1">9</button>
                        <button onClick={e => handleReplaceNumber(suggestAmounts[2]?.toString())} className="btn btn-gray w-100 h-60px fs-1">{formatCurrent(suggestAmounts[2])}</button>
                    </div>
                    <div className="d-flex flex-stack gap-3 mb-3">
                        <button onClick={e => setValuePrice('0')} className="btn btn-icon btn-secondary p-10 fs-1">C</button>
                        <button onClick={e => handleNumber('0')} className="btn btn-icon btn-secondary p-10 fs-1">0</button>
                        <button onClick={e => handleNumber('00')} className="btn btn-icon btn-secondary p-10 fs-1">00</button>
                        <button onClick={e => handleSplitNumber()} className="btn btn-secondary w-100 h-60px fs-1"><i className="bi bi-backspace fs-1 fw-bold"></i></button>
                    </div>
                    <div className="d-flex flex-stack gap-3 mb-3">
                        <button className="btn w-100 btn-secondary fs-3">{t('payment-terminal-deep.even-split')}</button>
                        <button className="btn w-100 btn-secondary fs-3">{t('payment-terminal-deep.rewards')}</button>
                        <button className="btn w-100 btn-secondary fs-3">{t('payment-terminal-deep.service')}</button>
                    </div>
                </div>
                <div className="col-5">
                    <div className="mb-2 h-50px">
                        <button disabled={balanceTotal() <=0} onClick={e=>methodPaymentApply('Cash')} className={clsx("btn border border-dashed border-warning  w-100 p-2 h-100 btn-light-warning bg-active-success",)}>{t('payment-terminal-deep.cash')}</button>
                    </div>
                    <div className="d-flex gap-1 mb-1 h-50px">
                        <button disabled={balanceTotal() <=0} onClick={e=>methodPaymentApply('Momo')} className={clsx("btn border border-dashed border-warning  w-100 p-2 h-100 btn-light-warning bg-active-success")}>Momo</button>
                        <button disabled={balanceTotal() <=0} onClick={e=>methodPaymentApply('Gift card')} className={clsx("btn border border-dashed border-warning  w-100 p-2 h-100 btn-light-warning bg-active-success")}>{t('payment-terminal-deep.gift-card')}</button>
                    </div>
                    <div className="d-flex gap-1 mb-1 h-50px">
                        <button disabled={balanceTotal() <=0} onClick={e=>methodPaymentApply('Payoo')} className={clsx("btn border border-dashed border-warning  w-100 p-2 h-100 btn-light-warning bg-active-success")}>Payoo</button>
                        <button disabled={balanceTotal() <=0} onClick={e=>methodPaymentApply('VN-Pay')} className={clsx("btn border border-dashed border-warning  w-100 p-2 h-100 btn-light-warning bg-active-success")}>VN-Pay</button>
                    </div>
                </div>
            </div>
        </div>
    </div> 
    </Modal.Body>
    }
    
  </Modal>

    </>
    
}
export const HeadItemTemplate = (props: any) => {
    const { t } = useTranslation();
    let {item,index, orderActive, checkPaid, isTagPaid} = props
   
    // console.log('item',item)
    return <div className={clsx('item bg-white mb-3 p-4 border border-2',{'active bg-primary bg-opacity-10 border-primary': index == orderActive})}>
    <div className="d-flex flex-stack">
        <span>#{item.code} {item.tablename} - {item.sectionname}
        {
            isTagPaid && <>
                <span className={clsx(`ms-2 badge badge-${checkPaid.badge}`)}>{checkPaid.label}</span>
            </>
        }
        </span>
        <span>{item.saletype}</span>
    </div>
    <div className="d-flex flex-stack">
        <span>{t('payment-terminal-deep.received-to')} {new Date(item.transactiondate).toLocaleDateString('GB-en')} at {item.transactiontime}</span>
        {item.transactiontime && <>
            <span className="text-gray-500 d-flex flex-center gap-1"><i className="bi bi-clock"></i> 
                <div className="text-end m-auto">
                    {
                        (item.transactionstatus == 'Completed' || item.transactionstatus == 'Cancel') && item.DiningTableHistoryEntries ?
                        <span>{new Date(item.DiningTableHistoryEntries[0]?.createddate).toLocaleString('en-GB', { hour12: false })}</span>
                        :
                        <CountUpTimer starttime={new Date(`${item.transactiondate} ${item.transactiontime}`).getTime()} />
                    }
                </div> 
            </span>
            
        </>
        }
    </div>
    <span className="arrow"><i className="fs-2 bi bi-caret-right-fill text-primary"></i></span>
</div>
}
export const ModalChooseTable = (props: any) => {
    const { t } = useTranslation()
    let {showTable, handleCloseModal, funcCallback, isTransfer, disabledId, dataEdit} = props
    const [tableDisabled,setTableDisabled] = useState<any>([])
    useEffect(()=>{
        if(dataEdit && dataEdit.islocked) {
            handleCloseModal(false)
        }
    },[dataEdit])
    useEffect(()=>{
        if(showTable) {
            getTable()
            if(disabledId) {
                setTableDisabled(disabledId)
            }
        }
    },[showTable])
    let staffInfo = useStoreMode((e: any)=> e.info)?.staffInfo || {}
    const [dataTabTable,setDataTabShowTable] = useState<any>([])
    const [tableInfo,setTableInfo] = useState<any>({})
    // console.log('dataTabTable',dataTabTable)
    const [tab,setTab] = useState<any>({})
    const [transColors,setTransColors] = useState<any>([])

    const getTransactionColors = async(ids: any) => {
        try {
            const urlApi = `smarthub/hosptransactions/get/transactioncolors`
            let tempData = {
                "transactionids": ids,
            }
            ApiService.post(urlApi, tempData)
            .then(({ data }) => {
              if(data) {
                  setTransColors(data)
              }
          })
          .catch(({ response }) => {
          console.log("err", response);
          });
  
        } catch (error) { ApiShowError(error) }
      }
    const getTable = () => {
        setTableInfo({})
        ApiService.get(`smarthub/hospdiningareasections/merchant2/${staffInfo.merchantid}`)
        .then(({ data }) => {
            if(data && data.length != 0) {
                let tempTabs = sortByNumber(data,'seq')
                setDataTabShowTable(tempTabs)
                let a = [] as any
                data.forEach((i: any) => {
                    i.DiningTables?.forEach((item: any) => {
                      if(item.Trans && item.Trans.length > 1) {
                        item.Trans = sortByNumber(item.Trans,'code','DESC')
                      }
                      a = a.concat(item.Trans?.map((h: any) => {
                        return {
                          ...h,
                          HistoryEntries: item.HistoryEntries,
                          tablename: item.tablename,
                          tableno: item.tableno,
                          tableid: item.id,
                          sectionname: i.sectionname,
                          "sectionid": i.id,
                          "sectioncode": i.sectioncode,
                        }
                      })||[])
                    })
                  })
                getTransactionColors(a.map((i: any) => i.id))
                setTab(tempTabs[0])
            }
        })
        .catch(({ response }) => {
        console.log("err", response);
        });
       
       
    }
    const settings = {
        // className: "center",
        infinite: false,
        // centerPadding: "60px",
        slidesToShow: 1,
        swipeToSlide: true,
        swipe: true,
        // rows: 3,
        slidesPerRow: 1,
        slidesToScroll: 1,
        dots: true,
        arrow: false,
        centerPadding: "20px",
    };
    const sliderRef = useRef<Slider | null>(null);
    const templateItemTable = () => {
        let data = sortByNumber(tab.DiningTables,'tableno')
        let children = [] as any
        let tempData = [] as any
        // console.log('datatable',data)
        let page = 21
        data.forEach((i: any,index: number) =>  {
            if(index && index % page == 0) {
                tempData.push(children)
                children = []
            }
            children.push(i)

        })
        if(page*tempData.length < data.length) {
            tempData.push(children)
        }
        // console.log('tempData',tempData)
        return tempData?.map((item: any,index: number) => {

            return <div className="d-flex gap-3 flex-wrap" key={index}>
                { item.map((item: any,index: number) =>(
                        <div key={index} className={clsx("cursor-pointer p-3 rounded-2 w-100px h-120px text-center fs-7 d-flex flex-center mb-4 border",
                            {
                                // 'bg-light-success': item.HistoryEntries.length != 0 && item.HistoryEntries[0]?.type != STATUSTABLE.free && !tableDisabled.includes(item.id),
                                'disabled': (item.HistoryEntries.length != 0 && item.HistoryEntries[0]?.type != STATUSTABLE.free) && !isTransfer,
                                // 'bg-light-warning': item.HistoryEntries?.length == 0 || item.HistoryEntries[0]?.type == STATUSTABLE.free, //!item.diningtablestatus || item.diningtablestatus == STATUSTABLE.free,
                                
                                'active border-2 border-warning': item.id == tableInfo?.id,
                                // 'bg-orange text-white border-orange border-solid': item.Trans && item.Trans.length != 0 && getColorTrans(item.Trans[0],transColors) == 'orange',
                                // 'bg-success text-white border-success border-solid': item.Trans && item.Trans.length != 0 && getColorTrans(item.Trans[0],transColors) == 'green',
                                // 'bg-danger bg-opacity-20 border-danger border-solid': item.Trans && item.Trans.length != 0 && getColorTrans(item.Trans[0],transColors) == 'red',
                                // 'bg-warning bg-opacity-20 border-warning border-solid': item.Trans && item.Trans.length != 0 && getColorTrans(item.Trans[0],transColors) == 'yellow',
                                // 'bg-light-info border-info border-solid':item.HistoryEntries && item.HistoryEntries.length != 0 && item.HistoryEntries[0]?.type != STATUSTABLE.free && item.Trans.length == 0,
                                // 'bg-gray-100 border-primary border-dashed':(!item.HistoryEntries || item.HistoryEntries && (item.HistoryEntries.length == 0 || item.HistoryEntries[0]?.type == STATUSTABLE.free)),
                                'disabled bg-gray border-gray border-solid ': tableDisabled.includes(item.id),
                                
                                'bg-orange text-white border-orange border-solid disabled': item.Trans && item.Trans.length != 0 && (item.Trans[0]?.receiptno || !item.Trans[0]?.grossamount) && getColorTrans(item.Trans[0],transColors) == 'orange',
                                'bg-opacity-50': item.Trans && item.Trans.length != 0 && (item.Trans[0]?.receiptno || !item.Trans[0]?.grossamount) && item.Trans[0]?.lockinfo?.staffcode == staffInfo.code && getColorTrans(item.Trans[0],transColors) == 'orange',
                                // 'disabled': ,
                                'bg-success text-white border-success border-solid': item.Trans && item.Trans.length != 0 && item.Trans[0]?.receiptno && getColorTrans(item.Trans[0],transColors) == 'green',
                                'bg-danger bg-opacity-20 border-danger border-solid': item.Trans && item.Trans.length != 0 && (item.Trans[0]?.receiptno || !item.Trans[0]?.grossamount) && getColorTrans(item.Trans[0],transColors) == 'red',
                                'bg-warning bg-opacity-20 border-warning border-solid': item.Trans && item.Trans.length != 0 && item.Trans[0]?.receiptno && getColorTrans(item.Trans[0],transColors) == 'yellow',
                                'bg-light-info border-info border-solid':item.HistoryEntries && item.HistoryEntries.length != 0 && item.HistoryEntries[0]?.type != STATUSTABLE.free && item.Trans.length == 0,
                                'bg-gray-100 border-primary border-dashed':(!item.HistoryEntries || item.HistoryEntries && (item.HistoryEntries.length == 0 || item.HistoryEntries[0]?.type == STATUSTABLE.free)),
                                'bg-info border-info border-solid text-white': item.Trans && item.Trans.length != 0 && !item.Trans[0]?.receiptno && item.Trans[0]?.grossamount,
                                'bg-gray border-gray text-gray-500 disabled': item.islocked,
                            })} onClick={e=>{
                                
                                if(item.diningtablestatus && item.diningtablestatus != STATUSTABLE.free) {
                                    // console.log('edit')
                                    // setOrderActive(index)
                                    // setEdit(true)
            
                                }
                                else {
                                    // console.log('add new',item)
                                    let temp = {
                                        ...item,
                                        "sectionid": tab.id,
                                        "sectioncode": tab.sectioncode,
                                        "sectionname": tab.sectionname,
                                    }
                                    setTableInfo(temp)
                                }
                            }}>
                                <div>
                                    {
                                        item.HistoryEntries && item.HistoryEntries[0]?.type == STATUSTABLE.occupied && item.Trans?.length != 0  && <div className={clsx({'opacity-0': !item.Trans[0]?.noofcovers})}>
                                            <i className="bi bi-person-circle text-reset me-1"></i> {item.Trans[0]?.noofcovers} guest
                                        </div>
                                    }
                                    {
                                        item.HistoryEntries && item.HistoryEntries[0]?.type == STATUSTABLE.occupied && <div className="text-center"><CountUpTimer starttime={new Date(item.HistoryEntries[0]?.seateddate).getTime()} /></div>
                                    }
                                    
                                    <div className="fs-1 fw-bold">{item.tableno}</div>
                                    
                                    {
                                        item.HistoryEntries && item.HistoryEntries[0]?.type == STATUSTABLE.occupied && <>
                                            <div>{formatCurrent(item.Trans[0]?.grossamount)} đ</div>
                                            <div className=""><i className="bi bi-person-hearts text-reset me-1"></i> {item.Trans[0]?.staffcode}</div>
                                        </>
                                    }
                                </div>
                            </div>
                        
                        ))
                    
                }
            </div>
        })
    }
    return <Modal show={showTable} size="lg" backdrop="static" keyboard={false} className="popup-in-popup backdrop-filter-blur-50" onHide={() => handleCloseModal()}>
    <Modal.Body className="bg-gray-200 p-5">
    <h3 className="mt-2 mb-5">{isTransfer?t('order-deep.select-table-and-area-to-move-table'):t('order-deep.select-a-table-and-area-to-order')} </h3>
    <div
            className='card d-flex flex-row text-nowrap scrollx-auto tab-custom-hub'
            data-kt-scroll='true'
            data-kt-scroll-activate='{default: false, lg: true}'
            data-kt-scroll-width='auto'
            data-kt-scroll-offset='0'
          >
            
            {dataTabTable?.map((item: any, index: number) => {
              return (<span
                key={index}
                id={item.id}
                onClick={() => {
                  setTab(item)
                  sliderRef.current?.slickGoTo(0)
                //   refetch()
                }}
                className={clsx(
                  ' cursor-pointer item-tab',
                  tab?.id != item.id && 'text-gray-600 ',
                  tab?.id == item.id && 'active',
                  index == 0 && ''
                )}>
                {item.sectionname}
              </span>)
            })}
          </div>
          

        <div className={clsx("py-4 overflow-y",{'d-flex flex-wrap gap-4': tab.DiningTables && tab.DiningTables.length < 22})}>
        {
            tab && tab.DiningTables &&  <>
            {tab.DiningTables.length > 21 ?
            <Slider ref={sliderRef} {...settings} className='mb-2' dotsClass='slick-dots w-100 slider-container'>
                {templateItemTable()}
            </Slider>:
            templateItemTable()
            }
            </>
            
        }
        
       </div> 
       <div className="text-end">
            <button className="btn btn-sm btn-secondary me-3" onClick={e=>{
                handleCloseModal()
            }}>{t("orders-hub-deep.cancel")}</button>
            <button disabled={!tableInfo.id} onClick={e=>{
                funcCallback && funcCallback({...tableInfo,isTransfer: isTransfer})
            }} className="btn btn-sm btn-primary me-auto">{t('orders-hub-deep.submit')}</button>
        </div>
    </Modal.Body>
</Modal>
}
export const TableTransfer = (props: any) => {
    const { t } = useTranslation();
    const {dataTable, setDataTable, selected, setSelected, idDefault} = props

    const handleClickLine = (item: any) => {
        if(!item.id) {
            return
        }
        let a = JSON.parse(JSON.stringify(selected))
        if(!a.includes(item.id)) {
            a.push(item.id)
            setSelected(a)
        }
        else {
            handleClickRemoveLine(item.id)
        }
    }
    const handleClickRemoveLine = (id: any) => {
        let a = JSON.parse(JSON.stringify(selected)).filter((i: any) => i != id)
        setSelected(a)
    }
    return <div className="table-responsive">
                <table className="table table-bordered border border-gray-500 table-row-bordered table-row-gray-500 g-2 mb-0">
                    <thead>
                    <tr className="fw-bold text-white">
                        <th className="bg-primary">{t('order-deep.description')}</th>
                        <th className="bg-primary bg-opacity-50">{t('order-deep.price')}</th>
                        <th className="bg-primary bg-opacity-50">{t('order-deep.qty')}</th>
                        <th className="bg-primary bg-opacity-50">{t('order-deep.disc')}</th>
                        <th className="bg-primary bg-opacity-50">{t('order-deep.amount')}</th>
                    </tr>
                    </thead>
                    <tbody>
                        {
                            dataTable.HospTransactionLines?.filter((i: any) => i.kitchenstatus != KITCHENSTATUS.voided)?.map((item: any,ind: number) => {
                                return <tr key={ind} onClick={e=>handleClickLine(item)} className={clsx('cursor-pointer',{'disabled bg-secondary': item.id && idDefault && idDefault?.includes(item.id),'bg-primary bg-opacity-15': selected.includes(item.id)})}>
                                <td>
                                    <div>{item.description}</div>
                                    {
                                        item.customizations?.map((i: any, index: number) => {

                                            return <div key={index} className="d-flex gap-1 fs-7 text-gray-600">
                                            <span>{i.quantity} x </span>
                                            <span>{i.description}</span>
                                            <span>({formatCurrent(i.price)})</span>
                                        </div>
                                        })
                                    }
                                </td>
                                <td className="">{item.price && formatCurrent(item.price)}</td>
                                <td className="">{item.quantity}</td>
                                <td className="">{item.discountamount && formatCurrent(+item.discountamount)}</td>
                                <td className={clsx({'opacity-0': item.isClone})}>{formatCurrent(item.amount)}</td>
                            </tr>
                            })
                        }
                    
                    </tbody>
                </table>
            </div>
}
export const ModalTransferTable = (props: any) => {
    let {showTable, handleCloseModal, funcCallback,transferTable,dataEdit} = props
    const [tableDisabled,setTableDisabled] = useState<any>([])
    const [selected,setSelected] = useState<any>([])
    const [selectedTranfer,setSelectedTranfer] = useState<any>([])
    
    useEffect(()=>{
        // console.log('transferTable',transferTable)
        // console.log('dataEdit',dataEdit)
        if((dataEdit && dataEdit.islocked) || (transferTable && transferTable.islocked)) {
            handleCloseModal(false)
        }
    },[dataEdit,transferTable])

    useEffect(()=>{
        if(showTable && transferTable && transferTable.Trans && transferTable.Trans.length != 0) {
            getTrans(transferTable.Trans[0]?.id)
        }
        else if(showTable && transferTable && transferTable.HospTransactionLines && transferTable.code) {
            console.log('transferTable',transferTable)
            getData(transferTable)
            // setDataTable(dataEdit)
        }
        else if(showTable) {
            let tempData = {
                HospTransactionLines: [],
                ...transferTable,
            }
            // console.log('tempData',tempData)
            getData(tempData)
        }
    },[transferTable,showTable])
    
    let staffInfo = useStoreMode((e: any)=> e.info)?.staffInfo || {}
    const [dataTable,setDataTable] = useState<any>({})
    const [dataTransferTable,setDataTransferTable] = useState<any>({})
    const [idTableDefault,setIdTableDefault] = useState<any>([])
    const [idTransferTableDefault,setIdTransferTableDefault] = useState<any>([])
    const getTrans = (id: any) => {
        setDataTransferTable({})
        ApiService.get(`smarthub/hosptransactions/${id}?expand=hosptransactionlines,kots,kotlines,DiningTableHistoryEntries,hospitem`)
        .then(({ data }) => {
            if(data ) {
                // console.log('setDataTransferTable',data)
                getData(data)
            }
        })
        .catch(({ response }) => {
        console.log("err", response);
        });
       
       
    }
    const getData = (data: any) => {
        let sum = data.HospTransactionLines.length + dataEdit?.HospTransactionLines.length
        // console.log('getData',data)
        data.HospTransactionLines = formatTableForTransfer(data.HospTransactionLines,sum)
        setDataTransferTable(data)
        setIdTransferTableDefault(data.HospTransactionLines.filter((i: any) => !i.isClone)?.map((i: any) => i.id)||[])

        let a = JSON.parse(JSON.stringify(dataEdit))
        a.HospTransactionLines = formatTableForTransfer(a.HospTransactionLines,sum)
        setIdTableDefault(dataEdit.HospTransactionLines?.map((i: any) => i.id)||[])
        setDataTable(a)
        // console.log('setDataTable',a)
        setSelected([])
        setSelectedTranfer([])
    }
    const sumTotal = (data: any) => {
        return data.HospTransactionLines?.reduce(function (acc:any, obj:any) { return acc + +obj.amount}, 0)||0
    }
    const formatTableForTransfer = (arr: any, totalLength: any) => {
        let tempData = arr
        if(totalLength <14) {
            totalLength = 14
        }
        let a = totalLength - arr.length
        let b = [] as any
        for(let i = 0;i<a;i++) {
            let temp = {
                description: '',
                quantity: '',
                amount: '',
                isClone: true,
            }
            b.push(temp)
        }
        
        return tempData.concat(b)
    }
    const transferAllData = () => {
        let a = JSON.parse(JSON.stringify(dataTable))
        // console.log('a',a)

        a.HospTransactionLines = a.HospTransactionLines.filter((i: any) => !i.isClone && i.kitchenstatus != KITCHENSTATUS.voided)
        let itemVoid = dataTable.HospTransactionLines.filter((i: any) => !i.isClone && i.kitchenstatus == KITCHENSTATUS.voided)||[]
        // console.log('itemVoid',itemVoid)
        // console.log('itemVoid',itemVoid)
        let b = JSON.parse(JSON.stringify(dataTransferTable))
        // console.log('dataTransferTable',dataTransferTable)

        b.HospTransactionLines = b.HospTransactionLines.filter((i: any) => !i.isClone)

        let sum = a.HospTransactionLines.length + b.HospTransactionLines.length
        b.HospTransactionLines = formatTableForTransfer(b.HospTransactionLines.concat(a.HospTransactionLines),sum)
        a.HospTransactionLines = formatTableForTransfer(itemVoid,sum)
        if(!b.transactionstatus) {
            b.transactionstatus = a.transactionstatus
        }
        if(!b.kitchenstatus) {
            b.kitchenstatus = a.kitchenstatus
        }
        if(!b.customerinfo) {
            b.customerinfo = a.customerinfo
        }
        if(!b.message) {
            b.message = a.message
        }
        setDataTable(a)
        // console.log('b',b)
        setDataTransferTable(b)
        setSelected([])
        setSelectedTranfer([])
    }
    const transferRowNext = () => {
        let a = JSON.parse(JSON.stringify(dataTable))
        a.HospTransactionLines = a.HospTransactionLines.filter((i: any) => !i.isClone)
        let b = JSON.parse(JSON.stringify(dataTransferTable))
        b.HospTransactionLines = b.HospTransactionLines.filter((i: any) => !i.isClone)

        let sum = a.HospTransactionLines.length + b.HospTransactionLines.length
        let d = a.HospTransactionLines.filter((i: any) => selected.includes(i.id))
        let e = a.HospTransactionLines.filter((i: any) => !selected.includes(i.id))
        b.HospTransactionLines = formatTableForTransfer(b.HospTransactionLines.concat(d),sum)
        setDataTransferTable(b)
        a.HospTransactionLines = formatTableForTransfer(e,sum)
        setDataTable(a)
        setSelected([])
        setSelectedTranfer([])
    }
    const transferRowPrev = () => {
        let a = JSON.parse(JSON.stringify(dataTable))
        a.HospTransactionLines = a.HospTransactionLines.filter((i: any) => !i.isClone)
        let b = JSON.parse(JSON.stringify(dataTransferTable))
        b.HospTransactionLines = b.HospTransactionLines.filter((i: any) => !i.isClone)

        let sum = a.HospTransactionLines.length + b.HospTransactionLines.length
        let c = b.HospTransactionLines.filter((i: any) => selectedTranfer.includes(i.id))
        let d = b.HospTransactionLines.filter((i: any) => !selectedTranfer.includes(i.id))
        b.HospTransactionLines = formatTableForTransfer(d,sum)
        a.HospTransactionLines = formatTableForTransfer(a.HospTransactionLines.concat(c),sum)
        setDataTransferTable(b)
        setDataTable(a)
        setSelected([])
        setSelectedTranfer([])
    }
    const handleTranfer = () => {
        // console.log('dataTable',dataTable)
        // console.log('dataTransferTable',dataTransferTable)
        funcCallback && funcCallback({dataTable, idTableDefault, dataTransferTable, idTransferTableDefault})
    }
    const { t } = useTranslation()
    return <Modal show={showTable} size="lg" fullscreen backdrop="static" keyboard={false} className="popup-in-popup backdrop-filter-blur-50" onHide={() => handleCloseModal(false)}>
    <Modal.Body className="bg-gray-200 p-0">
    <div className="row text-center g-0 text-white">
        <div className="col-6 p-4 bg-primary">
            {t('order-deep.transfer-from')} {dataTable.tablename}  - {t('orders-hub-deep.amount')}: {formatCurrent(sumTotal(dataTable))}
        </div>
        <div className="col-6  p-4 bg-primary">
            {t('order-deep.to')} {dataTransferTable.tablename}  - {t('orders-hub-deep.amount')}: {formatCurrent(sumTotal(dataTransferTable))}
        </div>
        <div className="col-12 p-4 bg-primary bg-opacity-75">
            {t('order-deep.lines-to-transfer')}: 
        </div>
    </div>
    <div className="row g-0 mb-5 wrap-table-transfer">
        <div className="col">
            <TableTransfer setDataTable={setDataTable} dataTable={dataTable} selected={selected} setSelected={setSelected}/>
        </div>
        <div className="col-1 text-center fs-2 text-white">
            <div onClick={transferAllData} className="w-100 notice bg-primary border-primary border border-solid h-33 d-flex flex-center cursor-pointer">{t('order-deep.mark-all')}</div>
            <div onClick={transferRowNext} className="w-100 notice bg-primary bg-opacity-50 border-primary border border-solid h-33 d-flex flex-center cursor-pointer"><i className="fs-2x bi text-reset bi-caret-right-fill"></i></div>
            <div onClick={transferRowPrev} className="w-100 notice bg-primary bg-opacity-50 border-primary border border-solid h-33 d-flex flex-center cursor-pointer"><i className="fs-2x bi text-reset bi-caret-left-fill"></i></div>
            {/* <div className="w-100 notice bg-light-primary border-primary border border-solid p-4">Cancel last</div> */}
            {/* <div className="w-100 notice bg-primary border-primary border border-solid p-4">Cancel all</div> */}
        </div>
        <div className="col">
            <TableTransfer setDataTable={setDataTransferTable} dataTable={dataTransferTable} idDefault={idTransferTableDefault} selected={selectedTranfer} setSelected={setSelectedTranfer}/>
        </div>
    </div>
    <div className="row text-center">
        <div className="col"><button onClick={handleCloseModal} className="btn btn-gray min-w-150px m-auto">{t('orders-hub-deep.cancel')}</button></div>
        <div className="col"><button disabled={dataTransferTable.HospTransactionLines?.filter((i: any) => !i.isClone).length == idTransferTableDefault.length} onClick={handleTranfer} className="btn btn-primary min-w-150px m-auto">{t('order-deep.transfer')}</button></div>
    </div>

       <div className="text-end">
            {/* <button className="btn btn-sm btn-secondary me-3" onClick={e=>{
                handleCloseModal()
            }}>Cancel</button> */}
            {/* <button disabled={!tableInfo.id} onClick={e=>{
                funcCallback && funcCallback(tableInfo)
            }} className="btn btn-sm btn-primary me-auto">Ok</button> */}
        </div>
    </Modal.Body>
</Modal>
}
export const getItemFromMenu = (dataMenu: any, item: any) => {
    let data = null as any
    let {itemno} = item
    if(!itemno) {
        itemno = item.TriggerCode
    }
    dataMenu?.forEach((i: any) => {
        if(i.code && i.code == itemno) {
            data = i
        }
        else if(i.hostitems && !data) {
            data = i.hostitems?.find((i: any) => i.code == itemno)
        }
    })
    // console.log('ádadasa',data)
    if(item.kotline) {
        data.kotline = item.kotline
    }
    data.kitchenstatus = item.kitchenstatus
    data.ispaid = item.ispaid
    data.message = item.message
    data.receiptno = item.receiptno
    data.lineno = item.lineno
    return data
}
export const CanvasViewlogsPrinter = (props: any) => {
    const { t } = useTranslation()
    const {isShow,setIsShow} = props
    const tabs = [{
        id: 1,
        label: 'View logs',
    },
    {
        id: 2,
        label: 'Check printer',
    }]
    const [printerMappings,setPrinterMappings] = useState<any>()
    const [tab,setTab] = useState<any>(1)
    const [dataLogs,setDataLogs] = useState<any>([])
    const [dataList,setDataList] = useState<any>([])
    const [activeTab,setActiveTab] = useState<any>(``)
    
    useEffect(() => {
       const getLogs = async()=>{
            let logsPrinter = await localforage.getItem('logsPrinter')||[] as any
            setDataLogs(logsPrinter)
       } 
       const getPrinters = async()=>{
            let merchantsData = await localforage.getItem('merchantsData')||{} as any
            let temp = renderDataDistinctLine(merchantsData.sectiondispstatmappings||[],'productionsectionname','productionsectioncode')
            console.log('temp',temp)
            if(temp && temp.length!= 0) {
                setActiveTab(temp[0].key)
                setDataList(temp[0].items)
                setPrinterMappings(temp)
            }
        } 
       if(isShow) {
        getLogs()
        getPrinters()
       }
    },[isShow])
    const handleClose = () => {
        setIsShow(false)
    }
    localforage.getItem('logsPrinter').then(function(value) {
        setDataLogs(value)

    }).catch(function(err) {
        console.log(err);
    });
    return <Offcanvas show={isShow} className="offcanvas-custom shadow" onHide={handleClose} placement='end'>
    <Offcanvas.Header className='mb-2 py-3 shadow'>
        {/* <h5 className="offcanvas-title fs-3" id="offcanvasLabel">{t('order-deep.printer-debugs')}</h5> */}
        <div
                className='card d-flex flex-center gap-2 flex-row text-nowrap scrollx-auto'
                data-kt-scroll='true'
                data-kt-scroll-activate='{default: false, lg: true}'
                data-kt-scroll-width='auto'
                data-kt-scroll-offset='0'
              >
                {tabs?.map((item: any, index: number) => {
                  return (<span
                    key={index}
                    id={item.id}
                    onClick={() => {
                      setTab(item.id)
                    }}
                    className={clsx(
                      ' cursor-pointer item-tab p-3 px-5',
                      tab != item.id && 'text-gray-600 ',
                      tab == item.id && 'active',
                      index == 0 && ''
                    )}>
                    {item.label}
                  </span>)
                })}
              </div>
        <button type="button" className="btn-close text-reset p-4" onClick={handleClose} data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </Offcanvas.Header>
    <Offcanvas.Body className='pt-0 px-0'>
            
        {
            tab == 1 &&
            <div className="px-4">
                <div className="notice bg-light-warning rounded border-warning border border-dashed p-2 wrap-logs-printer">
                    {
                        dataLogs && dataLogs.map((i: any,index: number) =>(
                            <div key={index}>{i}</div>
                        ))
                    }
                </div>
                <div className="text-end">
                    <button onClick={e=>localforage.setItem('logsPrinter',[])} className="btn btn-sm btn-primary mt-4 m-auto">Clear logs</button>
                </div>
            </div>
        }
        {
            tab == 2 && <div className="card px-4">
                <div className="">
                    <div className="card-title">
                        {/* <h3 className="m-0 text-gray-800">aaaaaaaa</h3> */}
                    </div>
                    <div className="card-toolbar m-0">
                        <ul className="nav nav-stretch d-flex flex-center gap-2 fw-bold nav-line-tabs border-transparent">
                            {
                                printerMappings && printerMappings.map((item: any,index: number) => {
                                    return <li key={index} onClick={e=>{
                                        setActiveTab(item.key)
                                        let temp = JSON.parse(JSON.stringify(item.items))
                                        delete temp.isLoading
                                        delete temp.returnStatus
                                        setDataList(temp)
                                    }} className="nav-item">
                                                <a href="#" className={clsx("nav-link ",{'active text-primary': activeTab == item.key},{'text-gray-600 ': activeTab != item.key})}>
                                                    {item.name}
                                                </a>
                                            </li>
                                })
                            }
                            
                        </ul>
                    </div>
                    <div className="pt-5 w-100">
                        {
                            dataList && dataList.map((item: any,index: number) => {
                                return <div className="d-flex flex-stack gap-3 border-bottom border-gray-300 pb-3 mb-3" key={index}>
                                    <div className="w-100">Printer {item.hospdiningareasectionname}</div>
                                    <div className="d-flex gap-2">
                                        {
                                            item.isLoading && 
                                                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                                        }
                                        {
                                            item.returnStatus && 
                                                <span className={clsx(`badge badge-light-${item.returnStatus == 'Failed'?'danger':'success'}`)}>{item.returnStatus}</span>
                                        }
                                        <span onClick={e=> {
                                            let {PrinterList} = item
                                            let temp = {
                                                host: PrinterList?.host,
                                                port: PrinterList?.port,
                                                name: item.productionsectioncode,
                                            }
                                            setDataList((dataList: any) => {
                                                dataList[index].isLoading = true
                                                return dataList
                                            })
                                            checkConnectPrinter(temp,index,setDataList)
                                        }} className={clsx("btn btn-sm btn-light-primary text-nowrap",{'disabled': item.isLoading})}>Test Connect</span>
                                    </div>

                                    
                                </div>
                            })
                        }
                    </div>
                </div>
                
            </div>
        }
    </Offcanvas.Body>
</Offcanvas>
}